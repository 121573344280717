import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements OnInit {

  constructor(public modalController: ModalController, private navController: NavController, private route: Router) { }

  ngOnInit() { }


  mailto(email) {
    window.open(`mailto:${email}`, '_system');
  }

   async dismissModal() {
    const isModalOpened = await this.modalController.getTop();
    if (isModalOpened) {this.modalController.dismiss();}
    else{
      this.route.navigate(['home']);
    }
  }

}
