import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { ShowHidePasswordComponent } from './show-hide-password/show-hide-password.component';
import { SettingsComponent } from './user-settings/settings.component';
import { MatchProfileComponent } from './match-profile/match-profile.component';
import { ExpandableComponent } from './expandable/expandable.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { CommunicationComponent } from './communication/communication.component';
import { DegrcertcheckComponent } from './degrcertcheck/degrcertcheck.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule
  ],
  declarations: [
    ShowHidePasswordComponent,
    SettingsComponent,
    MatchProfileComponent,
    ExpandableComponent,
    TermsComponent,
    PrivacyPolicyComponent,
    CommunicationComponent,
    DegrcertcheckComponent
  ],
  exports: [
    ShowHidePasswordComponent
  ]
})
export class ComponentsModule {}
