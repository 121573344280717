import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { switchMap, startWith, tap, filter } from 'rxjs/operators';

import { auth } from 'firebase';
import * as firebase from 'firebase/app';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';
import { first } from 'rxjs/operators';



@Injectable({
    providedIn: 'root'
})
export class AuthService {

    user: Observable<User | null>;

    constructor(
        private afAuth: AngularFireAuth,
        private afs: AngularFirestore,
        private router: Router
    ) {
        this.user = this.afAuth.authState.pipe(
            switchMap(user => {
                if (user) {
                    return this.afs.doc<User>(`${environment.firestorePath}/users/${user.uid}`).valueChanges();
                } else {
                    return of(null);
                }
            })
        );
    }

    ////// OAuth Methods /////


    isLoggedIn() {
        return this.afAuth.authState.pipe(first()).toPromise();
    }

    async googleLogin(useraf: any) {
        const provider = new auth.GoogleAuthProvider();
        return this.oAuthLogin(provider);
    }

    facebookLogin() {
        const provider = new auth.FacebookAuthProvider();
        return this.oAuthLogin(provider);
    }

    twitterLogin() {
        const provider = new auth.TwitterAuthProvider();
        return this.oAuthLogin(provider);
    }

    private oAuthLogin(provider: any) {
        return this.afAuth
            .signInWithPopup(provider)
            .then(credential => {
                console.log('Welcome to Google Login');
                return credential;
            })
            .catch(error => this.handleError(error));
    }

    //// Anonymous Auth ////

    anonymousLogin() {
        return this.afAuth
            .signInAnonymously()
            .then(credential => {
                return credential; // if using firestore
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    //// Email/Password Auth ////

    emailSignUp(email: string, password: string, useraf: any) {
        return this.afAuth
            .createUserWithEmailAndPassword(email, password)
            .then(credential => {
                this.updateUserData(credential.user, useraf); // if using firestore
            })
    }

    emailLogin(email: string, password: string) {
        return this.afAuth
            .signInWithEmailAndPassword(email, password)
            .then(credential => {
                return credential;
            })
    }

    SendVerificationMail() {

        //returning currentuser promise
    }

    updatePassword(password) {
        return firebase.auth().currentUser.updatePassword(password);
    }

    checkVerification() {
        return this.afAuth; //returning user promise
    }

    // Sends email allowing user to reset password
    resetPassword(email: string) {
        const fbAuth = auth();

        return fbAuth
            .sendPasswordResetEmail(email);
    }

    signOut() {
        return this.afAuth.signOut();
    }

    // If error, console log and notify user
    private handleError(error: Error) {
        console.error(error);
        console.log(error.message, 'error');
    }

    // Sets user data to firestore after succesful login
    updateUserData(user: any, useraf: any) {
        const userRef: AngularFirestoreDocument<User> = this.afs.doc(
            `${environment.firestorePath}/users/${user.uid}`
        );

        const data: User = {
            uid: user.uid,
            email: useraf.email || '',
            firstName: useraf.firstName,
            lastName: useraf.lastName,
            year: new Date(useraf.year).getFullYear(),
            userName: useraf.userName || '',
            gender: useraf.gender,
            photoURL: useraf.photoURL || '',
            role: useraf.role || '',
            profileCompleted: false,
            stage: 1,
            edit: false,
            joinedOn: new Date().getTime(),
            emailVerified: user.emailVerified
        };
        userRef.set(data);
        //Saving UserName
        this.afs.collection(`${environment.firestorePath}/usernames`).doc(useraf.userName.toLowerCase()).set({ uid: user.uid });
        return data;
    }
    //Check for duplicate username
    checkUsername(username: string) {
        username = username?.trim().toLowerCase();
        return this.afs.doc(`${environment.firestorePath}/usernames/${username}`).valueChanges();
    }

}
