import { Component, OnInit, Input } from "@angular/core";
import { NavParams, ModalController } from "@ionic/angular";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit {
  @Input() userInfo: any;
  location: any = false;
  age: any = false;
  locValue: any;
  ageValue: any;

  constructor(
    public navParams: NavParams,
    public modalController: ModalController,
    private userService: UserService
  ) {}

  ngOnInit() {
    console.log(this.userInfo);

    if(this.userInfo.locationPrefrence === true){this.locValue = 'yes' }
    else if(this.userInfo.locationPrefrence === false){this.locValue = 'no'}
    else{this.locValue = 'nop'}

    if(this.userInfo.agePrefrence === true){this.ageValue = 'yes' }
    else if(this.userInfo.agePrefrence === false){this.ageValue = 'no'}
    else{this.ageValue = 'nop'}
    
  }

  dismissModal(reload) {
    this.modalController.dismiss(reload);
  }

  addExpInt(val) {
    if (this.userInfo.categories.indexOf(val) !== -1) {
      this.userInfo.categories.splice(this.userInfo.categories.indexOf(val), 1);
    } else {
      this.userInfo.categories.push(val);
    }
  }

  savePreferences() {
    if (this.ageValue == "yes") {
      this.age = true;
    } else if(this.ageValue == "no"){
      this.age = false;
    }else{
      this.age = null;
    }

    if (this.locValue == "yes") {
      this.location = true;
    } else if(this.locValue == "no"){
      this.location = false;
    }else{
      this.location = null;
    }

    let data = {
      categories: this.userInfo.categories,
      locationPrefrence: this.location,
      agePrefrence: this.age,
    };
    this.userService
      .updateUser(this.userInfo.uid, data)
      .then(() => {
        this.dismissModal(true);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  selectAge(val) {
    this.ageValue = val;
  }

  selectLoc(val) {
    this.locValue = val;
  }
}
