import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from "src/environments/environment";
import { HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';

let headers = new HttpHeaders({
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
  'Access-Control-Allow-Headers': 'Content-Type, X-Auth-Token, Origin, Authorization'
});

@Injectable({
  providedIn: 'root'
})
export class UserService {
  products: any;
  clientRef: any;

  constructor(private afs: AngularFirestore, private auth: AuthService) { 

  }

  // getUser(collection){
  //   return this.afs.collection(`${environment.firestorePath}/users`).valueChanges();
  // }

  getUser(collection, doc){
    return this.afs.collection(`${environment.firestorePath}/users`).doc(doc).valueChanges();
  }

  updateUser(userId, paylaod){
    return this.afs.collection(`${environment.firestorePath}/users`).doc(userId).update(paylaod);
  }

  addCertificates(userId, certificate){
    return this.afs.collection(`${environment.firestorePath}/users`).doc(userId).collection('certifications').add(certificate);
  }

  addDegreess(userId, degree){
    return this.afs.collection(`${environment.firestorePath}/users`).doc(userId).collection('degrees').add(degree);
  }

  updateCertificate(userId, certificate){
    return this.afs.collection(`${environment.firestorePath}/users`).doc(userId).collection('certifications').doc(certificate.id).set({cert: certificate.cert, inst:certificate.inst });
  }

  updateDegree(userId, degree){
    return this.afs.collection(`${environment.firestorePath}/users`).doc(userId).collection('degrees').doc(degree.id).set({degr: degree.degr, inst:degree.inst });
  }

  deleteCertificate(userId, certificate){
    return this.afs.collection(`${environment.firestorePath}/users`).doc(userId).collection('certifications').doc(certificate.id).delete();
  }

  deleteDegree(userId, degree){
    return this.afs.collection(`${environment.firestorePath}/users`).doc(userId).collection('degrees').doc(degree.id).delete();
  }

  getCertificates(userId){
    return this.afs.collection(`${environment.firestorePath}/users`).doc(userId).collection('certifications').snapshotChanges();
  }

  getDegrees(userId){
    return this.afs.collection(`${environment.firestorePath}/users`).doc(userId).collection('degrees').snapshotChanges();
  }

  addSubscription(userId, subcription){
    return this.afs.collection(`${environment.firestorePath}/subscriptionHistory`).doc(userId).collection('subscription').add(subcription);
  }





}
