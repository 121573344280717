import { Component, OnInit, Input } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-degrcertcheck',
  templateUrl: './degrcertcheck.component.html',
  styleUrls: ['./degrcertcheck.component.scss']
})
export class DegrcertcheckComponent implements OnInit {
  @Input() user: any;
  constructor(navParams: NavParams, public modalController: ModalController) { }

  ngOnInit(): void {
  }


  dismissModal() {
    this.modalController.dismiss({
    });
  }

}
