// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAe2d0jfgUTvEdKvJUQMYJfdjgIie2wYX4",
    authDomain: "wellnessnow-4e968.firebaseapp.com",
    databaseURL: "https://wellnessnow-4e968.firebaseio.com",
    projectId: "wellnessnow-4e968",
    storageBucket: "wellnessnow-4e968.appspot.com",
    messagingSenderId: "448634920987",
    appId: "1:448634920987:web:8de02e52a354aca0dcd8be",
    measurementId: "G-6E8QS5LSFH"
  },
  firestorePath:'root/development',
  validatorURL: 'https://validator.fovea.cc/v1/validate?appName=com.lisa.wellnessmatch&apiKey=8320df04-bc41-4178-8e5e-1a2225269068',
  itunesVerifyApi:'https://buy.itunes.apple.com/verifyReceipt',
  itunesSandboxVerifyApi:'https://sandbox.itunes.apple.com/verifyReceipt',
  app_specific_password:'ae7729b4ee9c41ad8db1a496829e7123'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
