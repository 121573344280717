import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController, AlertController } from '@ionic/angular';
import { UserService } from 'src/app/services/user.service';
import { CallNumber } from '@ionic-native/call-number/ngx';

@Component({
  selector: 'app-match-profile',
  templateUrl: './match-profile.component.html',
  styleUrls: ['./match-profile.component.scss']
})
export class MatchProfileComponent implements OnInit {
  user: any;
  categories: any;
  public items: any = [];
  showList: boolean;
  showDegree: boolean;
  showCertification: boolean;
  _degrees: any;
  _certificates: any[];
  constructor(navParams: NavParams, public modalController: ModalController, public userService: UserService, private callNumber: CallNumber, private alertController: AlertController) {
    this.user = navParams.get('user');
    console.log(this.user);
    this.categories = this.user.categories;
    this.user.age = 2020 - this.user.year;
    this.addListData(this.user);
    if (this.user.role === 'healer') {
      this.userService.getCertificates(this.user.uid).subscribe((data) => {
        this._certificates = [];
        data.map(rec => {
          console.log(rec.payload.doc.data());
          this._certificates.push({
            cert: rec.payload.doc.data()['cert'],
            inst: rec.payload.doc.data()['inst'],
            id: rec.payload.doc.id
          })
        });
        this.items[4] = { title: 'My Certificates', certificates: this._certificates, expanded: false };
        this.showCertification = true;
      });
      this.userService.getDegrees(this.user.uid).subscribe((data) => {
        this._degrees = [];
        data.map(rec => {
          console.log(rec.payload.doc.data());
          this._degrees.push({
            degr: rec.payload.doc.data()['degr'],
            inst: rec.payload.doc.data()['inst'],
            id: rec.payload.doc.id
          })
        });
        this.items[5] = { title: 'My Degrees', degrees: this._degrees, expanded: false };
        this.showDegree = true;
      });
    }
  }


  ngOnInit(): void {
  }

  dismissModal() {
    this.modalController.dismiss({
    });
  }

  addListData(user) {
    if (user.role === 'healer')
      this.items[0] = { title: 'My Philosophy', philosophy: user.philosophy ? user.philosophy : { ques1: 'How would you describe your philosophy on treatment?', ans1: '...' }, expanded: false };
    else
      this.items[0] = { title: 'My Desire', philosophy: user.philosophy ? user.philosophy : { ques1: 'Tell us more about what brought you here.', ans1: '...' }, expanded: false };

    this.items[1] = { title: 'My Hero', hero: user.hero ? user.hero[0] : { ques1: 'Who was your hero in childhood?', ans1: '...' }, why: user.hero ? user.hero[1] : { ques2: 'Why?', ans2: '...' }, expanded: false };

    this.items[2] = { title: 'My Hero Now', heroNow: user.heroNow ? user.heroNow[0] : { ques1: 'Who is your hero now?', ans1: '...' }, why: user.heroNow ? user.heroNow[1] : { ques2: 'Why?', ans2: '...' }, expanded: false };

    this.items[3] = { title: 'Dream', dream: user.dream ? user.dream : { ques1: 'If you could have one dream in the world, what would it be?', ans1: '...' }, expanded: false };

    console.log(this.items);

    this.showList = true;
  }


  expandItem(item): void {
    if (item.expanded) {
      item.expanded = false;
    } else {
      this.items.map(listItem => {
        if (item == listItem) {
          listItem.expanded = !listItem.expanded;
        } else {
          listItem.expanded = false;
        }
        return listItem;
      });
    }
  }

  getDiferenceInDays(theDate): number {
    let date = new Date(theDate);
    return Math.floor(
      Math.abs(date.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)
    );
  }

  sendEmail(){
    window.open( `mailto:${this.user.email}`, '_system' );
  }

  openCaller(){

    //console.log(this.user.phoneNumber);
    if(this.user.phone){
       this.callNumber.callNumber(this.user.phone, true)
    .then(res => console.log('Launched dialer!', res))
    .catch(err => console.log('Error launching dialer', err));
    }
    else{
      this.Alert('The user does not have a phone number');
    }
   
  }

  openLink(){
    if(this.user.link){
      window.open(`${this.user.link}`, '_system', 'location=yes');
   }
   else{
     this.Alert('The user does not have a website or a link');
   }
  }

  async Alert(msg) {
    const alert = await this.alertController.create({
      //cssClass: 'my-custom-class',
      header: "Information",
      //subHeader: 'Subtitle',
      message: msg,
      buttons: ["OK"],
    });

    await alert.present();
  }
}
