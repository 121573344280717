import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit {


  constructor(public modalController: ModalController, private route: Router) { }

  ngOnInit() {}

  mailto (email) {
    window.open( `mailto:${email}`, '_system' );
  }

  async dismissModal() {
    const isModalOpened = await this.modalController.getTop();
    if (isModalOpened) {this.modalController.dismiss();}
    else{
      this.route.navigate(['home']);
    }
  }

}
