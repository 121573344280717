import { Component, OnInit, Input } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { UserService } from '../../services/user.service';
import { AuthService } from '../..//services/auth.service';

@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.scss']
})
export class CommunicationComponent implements OnInit {
  @Input() user: any;
  //user:any = {};
  constructor(private auth: AuthService, navParams: NavParams, public modalController: ModalController, public userService: UserService) { }

  ngOnInit() {
    
  }

  async save(form) {
    console.log(form.value);
    const user = await this.auth.isLoggedIn();
   
    let data = { phone: form.value.tel, link: form.value.link }
    this.userService.updateUser(user.uid, data).then(() => {
      this.dismissModal();
    });
  }

  dismissModal() {
    this.modalController.dismiss({
    });
  }

}
